import React, { useState, useRef, useCallback, useMemo } from "react";

import { useSelector } from "react-redux";
import fullEntries from "selectors/entries";
import wrapGeo from "helpers/geo/wrapGeo";

import NeighboursTable from "./NeighboursTable";
import MapContainer from "./MapContainer";
import { EuiSpacer } from "@elastic/eui";

const MapPanel = ({ id }) => {
  const entries = useSelector(fullEntries);
  const entry = entries[id];
  const distanceRules = useSelector(state => state.distance_rules.items);
  const collections = useSelector(state => state.collections.items);
  const [query, setQuery] = useState({
    query: "",
    error: null,
    result: [],
    queryText: ""
  });
  const neighbours = useMemo(
    () =>
      entry.neighbours.results.map(n => {
        return {
          ...entries[n.id],
          distance: n.distance,

          distanceRules: Object.entries(entry.collisions.distancerules)
            .filter(([k, v]) => v.results.map(e => e.id).includes(n.id)) //TODO this is shit
            .map(([k, v]) => parseInt(k, 10))
        };
      }),
    [entry]
  );

  const tableData = useMemo(
    () =>
      neighbours.map(e => ({
        id: e.id,
        abstand: e.distance,
        regel: e.distanceRules.map(e => distanceRules[e].name),
        kollision: e.distanceRules.length > 0,
        eintrag: e.entryFormat,
        kollektion: e.collection_ids.map(e => collections[e].name),
        geo: e.geojson,
        color: e.color
      })),
    [neighbours]
  );
  const mapData = wrapGeo(
    (query.queryText === "" ? tableData : query.result).map(
      ({ geo, ...properties }) => ({
        geo,
        properties
      })
    )
  );
  return entry.geojson ? (
    <div>
      <MapContainer data={mapData} marker={[entry.geojson]} />
      <EuiSpacer />
      <NeighboursTable data={tableData} query={query} setQuery={setQuery} />
    </div>
  ) : null;
};

export default MapPanel;
