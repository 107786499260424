import React from "react";
import Content from "components/Content";

import Page from "components/Page";
const NewEntry = () => (
  <Page
    title="Einträge"
    // actions={<NewEntryButton />}
  >
    <Content title="Übersicht"></Content>
  </Page>
);
export default NewEntry;
