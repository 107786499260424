import { createSelector } from "@reduxjs/toolkit";
import jsonata from "jsonata";
import { fullEntriesMap } from "./entries";

function evaluate(entryValue, comperator, filterValues) {
  if (Array.isArray(entryValue)) {
    return entryValue.some((e) => evaluate(e, comperator, filterValues));
  }
  switch (comperator) {
    case "is":
      return (
        (entryValue === undefined || entryValue === false) === !filterValues
      );
    case "regex":
      return (
        entryValue !== undefined &&
        new RegExp(filterValues, "i").test(entryValue)
      );
    case "is_in":
      return entryValue !== undefined && filterValues.includes(entryValue);
    case "in_range":
      return entryValue >= filterValues[0] && entryValue <= filterValues[1];
    case "equal":
      return (
        entryValue !== undefined &&
        entryValue.toString() === filterValues.toString()
      ); //TODO: Shit
    default:
      return false;
  }
}

const filtersSelector = (state) => state.filters.items;
const filterValues = (state) => state.uiFilters;

const filtersMap = createSelector(filtersSelector, (filters) => {
  return Object.values(filters);
});

const activeFiltersMap = createSelector(
  filtersMap,
  filterValues,
  (filterGroups, values) => {
    const filters = filterGroups.map((e) => e.fields).flat();
    return filters.filter(
      (e) =>
        values[e.id] !== undefined &&
        values[e.id] !== "" &&
        (values[e.id].length > 0 || typeof values[e.id] === "boolean")
    );
  }
);

const filterdEntries = createSelector(
  fullEntriesMap,
  activeFiltersMap,
  filterValues,
  (entries, filters, values) => {
    const result = entries.filter((entry) =>
      filters.every((filter) => {
        const expression = jsonata(filter.path);
        const entryValues = expression.evaluate(entry);
        return evaluate(entryValues, filter.comperator, values[filter.id]);
      })
    );
    return result;
  }
);

const filterdEntriesMap = createSelector(
  filterdEntries,

  (entries) => {
    return Object.values(entries);
  }
);

export { filterdEntries, filterdEntriesMap };
