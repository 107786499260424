import React, { useRef, useMemo, useState, useCallback } from "react";
import { EditableGeoJsonLayer, ModifyMode } from "nebula.gl";
import { StaticMap } from "react-map-gl";
import { wrapGeoJson } from "helpers/geo/wrapGeo";
import DeckGL from "@deck.gl/react";
import buffer from "@turf/buffer";
import bbox from "@turf/bbox";

const MapField = ({ value, onChange, view }) => {
  const [oldValue, setOldValue] = useState(view);
  const [viewState, setViewState] = useState({
    longitude: 10.4515,
    latitude: 51.1657,
    zoom: 17,
    pitch: 0,
    bearing: 0
  });
  if (view !== oldValue) {
    setOldValue(view);
    setViewState({
      ...viewState,
      longitude: view.coordinates[0],
      latitude: view.coordinates[1]
    });
  }
  const mapRef = useRef();
  const handleViewStateChange = e => setViewState(e.viewState);
  const geojson = useMemo(() => {
    return wrapGeoJson({
      geojson: value,
      properties: { color: [4, 40, 25, 200] }
    });
  }, [value]);
  const setIntialView = useCallback(() => {
    if (value === undefined) return;
    const box = bbox(buffer(geojson, 1));
    const {
      longitude,
      latitude,
      zoom,
      pitch,
      bearing
    } = mapRef.current.deck.viewManager._viewports[0].fitBounds([
      [box[0], box[1]],
      [box[2], box[3]]
    ]);
    setViewState({ longitude, latitude, zoom, pitch, bearing });
  }, []);

  const selectedFeatureIndexes = geojson.features.length > 0 ? [0] : [];
  const source = new EditableGeoJsonLayer({
    data: geojson,
    id: "source",
    mode: ModifyMode,
    selectedFeatureIndexes,
    onEdit: e => onChange(e.updatedData.features[0].geometry)
  });

  return (
    <>
      <DeckGL
        onLoad={setIntialView}
        ref={mapRef}
        id="showCanvas"
        layers={[source]}
        controller={true}
        viewState={viewState}
        onViewStateChange={handleViewStateChange}
      >
        <StaticMap />
      </DeckGL>
    </>
  );
};

export default MapField;
