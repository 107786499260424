import React from "react";
import Form from "./Form";
import { useFormik } from "formik";
import { update } from "ducks/filters";
import { add } from "ducks/toasts";

import { connect } from "react-redux";
import { useRemoveQueryNavigation } from "helpers/hooks/url";

const EditFilter = ({ file, add, update }) => {
  const onClose = useRemoveQueryNavigation("editFilter");
  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: file,
    enableReinitialize: true,
    onSubmit: values => {
      update(values);
      add(
        "Update erfolgreich",
        "Datei wurde erfolgreich erstellt",
        "success",
        "user"
      );
    }
  });

  return values ? (
    <Form
      subtitle="Filter bearbeiten"
      values={values}
      handleChange={setFieldValue}
      handleSubmit={handleSubmit}
      handleClose={onClose}
    ></Form>
  ) : (
    false
  );
};
const mapState = (state, { id }) => ({ file: state.filters.items[id] });
export default connect(mapState, { update, add })(EditFilter);
