import React, { Fragment, useState } from "react";
import FormModal from "components/FormModal";
import { useSelector } from "react-redux";
import { useAddQueryNavigation } from "helpers/hooks/url";
import "./style.css";
import {
  EuiButtonIcon,
  EuiFormRow,
  EuiSpacer,
  EuiAspectRatio,
  EuiComboBox,
  EuiButton,
  EuiTextArea
} from "@elastic/eui";
import GeoCodeField from "components/FormFields/GeoCodeField";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import MapField from "components/FormFields/MapField";
const Form = React.memo(
  ({ values, handleChange, handleEdit, handleDelete, handleAdd, ...props }) => {
    const collections = useSelector(state => state.collections.items);
    const collectionOptions = Object.values(collections)
      .map(e => ({
        id: e.id,
        label: e.name
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    const addQuery = useAddQueryNavigation();
    const [viewState, setViewState] = useState(false);
    return (
      <FormModal title="Eintrag" {...props}>
        <EuiFormRow fullWidth>
          <EuiAspectRatio width={4} height={3}>
            <MapField
              value={values.geojson === null ? undefined : values.geojson}
              onChange={e => handleChange("geojson", e)}
              view={viewState}
            />
          </EuiAspectRatio>
        </EuiFormRow>
        <EuiFormRow fullWidth>
          <GeoCodeField
            handleClick={({ Longitude, Latitude, ...data }) => {
              console.log(data);
              const address = values.properties.find(
                e => e.property_template_id === 2
              );
              console.log(address);
              if (address) {
                addQuery("editProperty", address.id, {
                  data,
                  property_template_id: 2,
                  id: address.id,
                  entry_id: values.id
                });
              } else {
                addQuery("createProperty", values.id, {
                  data,
                  property_template_id: 2,
                  entry_id: values.id
                });
              }
            }}
            onChange={e => {
              const value = {
                type: "Point",
                coordinates: [e.data.Longitude, e.data.Latitude]
              };
              handleChange("geojson", value);
              setViewState(value);
            }}
          />
        </EuiFormRow>
        <EuiFormRow fullWidth>
          <EuiTextArea
            fullWidth
            onChange={e => handleChange("geojson", JSON.parse(e.target.value))}
            value={JSON.stringify(values.geojson)}
          />
        </EuiFormRow>
        <EuiFormRow label="Kollektionen" fullWidth>
          <EuiComboBox
            fullWidth
            selectedOptions={values.collection_ids.map(e => ({
              label: collections[e].name,
              id: e
            }))}
            onChange={e => {
              handleChange(
                "collection_ids",
                e.map(x => x.id)
              );
            }}
            placeholder="Kollektionen auswählen"
            options={collectionOptions}
            isClearable={true}
          />
        </EuiFormRow>
        <EuiFormRow label="Daten" fullWidth>
          <div>
            {values.properties.map((e, i) => (
              <Property
                {...e}
                index={i}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            ))}
          </div>
        </EuiFormRow>

        <EuiSpacer size="m" />

        <EuiFlexGroup justifyContent={"flexEnd"}>
          <EuiFlexItem grow={false}>
            <EuiButton size="s" onClick={handleAdd} iconType="listAdd">
              Feld hinzufügen
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </FormModal>
    );
  }
);

const Property = ({
  property_template_id,
  data,
  handleEdit,
  index,
  handleDelete
}) => {
  const format = useSelector(
    state => state.property_templates.items[property_template_id]
  );
  if (format === undefined) return false;
  const items = format.fields.map(e => ({
    id: e.id,
    title: e.label,
    description: data[e.label]
  }));

  return (
    <EuiFlexGroup responsive={false}>
      <EuiFlexItem>
        <dl
          style={{
            fontSize: "12px",
            borderLeft: "2px solid black",
            paddingLeft: "5px",
            marginBottom: "7px"
          }}
        >
          {items.map(e => (
            <Fragment key={e.id}>
              <dt style={{ fontWeight: 600, marginBottom: "4px" }}>
                {e.title}
              </dt>
              <dd style={{ marginBottom: "8px" }}>{e.description}</dd>
            </Fragment>
          ))}
        </dl>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          aria-label="Datensatz löschen"
          iconSize="s"
          className="entriesButtons"
          color="danger"
          iconType="trash"
          flush="right"
          onClick={() => handleDelete(index)}
        />
        <EuiButtonIcon
          aria-label="Datensatz bearbeiten"
          iconSize="s"
          className="entriesButtons"
          color="primary"
          iconType="pencil"
          flush="right"
          onClick={() => handleEdit(index)}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Form;
