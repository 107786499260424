import React from "react";
import FormModal from "components/FormModal";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  EuiFormRow,
  EuiFieldText,
  EuiSwitch,
  EuiSelect,
  EuiDatePicker,
  EuiFieldNumber
} from "@elastic/eui";
function createDefaults(format) {
  return format.fields.reduce((r, e) => {
    let v = "";
    if (e.type === "0") {
      v = "";
    }
    if (e.type === "1") {
      v = 0;
    }
    if (e.type === "2") {
      v = true;
    }
    if (e.type === "3") {
      v = moment();
    }
    if (e.type === "7") {
      v = moment();
    }
    if (e.type === "5") {
      v = "";
    }
    r[e.label] = v;
    return r;
  }, {});
}
const Form = ({ values, handleChange, ...props }) => {
  const formats = useSelector(state => state.property_templates.items);
  const format = formats[values?.property_template_id];

  return (
    <FormModal title="Daten" {...props}>
      <EuiFormRow label="Template">
        <EuiSelect
          onChange={e => {
            handleChange("property_template_id", e.target.value);
            handleChange("data", createDefaults(formats[e.target.value]));
          }}
          compressed
          hasNoInitialSelection
          value={values.property_template_id}
          options={Object.values(formats).map(e => ({
            value: e.id,
            text: e.name
          }))}
        />
      </EuiFormRow>

      {format &&
        format.fields.map(e => (
          <EuiFormRow label={e.label}>
            <Field
              label={e.label}
              options={e.options}
              type={e.type.toString()}
              value={values.data?.[e.label]}
              onChange={value => handleChange(`data.${e.label}`, value)}
            ></Field>
          </EuiFormRow>
        ))}
    </FormModal>
  );
};
export default Form;

const Field = ({ type, onChange, value, options, label }) => {
  if (type === "0") {
    return (
      <EuiFieldText
        compressed
        value={value ?? ""}
        onChange={e => onChange(e.target.value)}
      />
    );
  }
  if (type === "1") {
    return (
      <EuiFieldNumber
        compressed
        value={value ?? 0}
        onChange={e => onChange(parseInt(e.target.value, 10))}
      />
    );
  }
  if (type === "2") {
    return (
      <EuiSwitch
        compressed
        checked={value ?? false}
        onChange={() => onChange(!value)}
        label={label}
      />
    );
  }
  if (type === "3") {
    return (
      <EuiDatePicker
        locale="de-de"
        selected={
          value === "undefined" || value === null ? moment() : moment(value)
        }
        dateFormat="DD-MM-YYYY"
        onChange={e => {
          onChange(e);
        }}
      />
    );
  }
  if (type === "5") {
    return (
      <EuiSelect
        hasNoInitialSelection
        id="selectDocExample"
        options={options.map(e => ({ value: e, text: e }))}
        value={value}
        onChange={e => onChange(e.target.value)}
        aria-label="Use aria labels when no actual label is in use"
      />
    );
  }
  if (type === "7") {
    return (
      <EuiDatePicker
        locale="de-de"
        selected={
          value === "undefined" || value === null ? moment() : moment(value)
        }
        dateFormat="DD-MM-YYYY HH:mm"
        timeFormat="HH:mm"
        onChange={e => onChange(e)}
      />
    );
  }
  return <div>Error</div>;
};
