import React, { memo, Children } from "react";
import JSONBlock from "components/Code/JSONBlock";
import "./panel.scss";
import GeoCodeField from "components/FormFields/GeoCodeField";
import Entry from "./Entry";
import Search from "./Search";
import { useSelector, useDispatch } from "react-redux";
import { neighboursLookUp } from "helpers/hooks/useNeighboursLookUp";
import { setPicked } from "ducks/ui/entriesMap";
const MapPanel = function({ data }) {
  const dispatch = useDispatch();
  let content = undefined;
  const token = useSelector(state => state.auth.token);
  if (data.source === "Search") content = <Search data={data} />;
  if (data.source === "Entry") content = <Entry data={data} />;

  const onSearch = e => {
    const entry = {
      type: "Point",
      coordinates: [e.data.Longitude, e.data.Latitude]
    };
    neighboursLookUp(JSON.stringify(entry), 1000, token).then(x => {
      const result = {
        source: "Search",
        coordinates: [e.data.Longitude, e.data.Latitude],
        neighbours: { results: x.results, total: x.total },
        collisions: { distancerules: {} },
        collectionNames: [],
        entryFormat: [
          `${e.data.Straße} ${e.data.Hausnummer}`,
          `${e.data.Postleitzahl} ${e.data.Ort}`
        ]
      };
      dispatch(setPicked(result));
    });
  };

  return (
    <div className="mapPanel">
      <GeoCodeField onChange={onSearch} />
      {content}
      <div className="content"></div>
    </div>
  );
};

export default memo(MapPanel);
