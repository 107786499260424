import React, { useState } from "react";
import Form from "./CreateForm";
import { useFormik } from "formik";
import { update } from "ducks/entries";
import { add } from "ducks/toasts";
import PropertyForm from "components/Forms/Property";

import { connect } from "react-redux";
import { useRemoveQueryNavigation } from "helpers/hooks/url";
const CreateEntry = ({ add, update, entry }) => {
  const onClose = useRemoveQueryNavigation("createEntry");
  const [selectedProperty, setSelectedProperty] = useState(false);

  const { values, handleSubmit, setFieldValue } = useFormik({
    // enableReinitialize: true,
    initialValues: {
      properties: [
        { property_template_id: 2, data: { Straße: "Test" } },
        { property_template_id: 2, data: { Straße: "LA" } },
        { property_template_id: 2, data: { Straße: "LU" } }
      ],
      collection_ids: []
    },

    onSubmit: values => {
      update(values);
      add(
        "Update erfolgreich",
        "Benutzer wurde erfolgreich aktualisiert",
        "success",
        "user"
      );
    }
  });
  console.log(values);
  return selectedProperty === false ? (
    <Form
      subtitle="Eintrag erstellen"
      values={values}
      handleChange={setFieldValue}
      handleSubmit={handleSubmit}
      handleClose={onClose}
      handleAdd={() => {
        setFieldValue("properties", [
          ...values.properties,
          { property_template_id: 2, data: { Straße: "JOJO" } }
        ]);
        setSelectedProperty(values.properties.length);
      }}
      handleEdit={e => setSelectedProperty(e)}
      handleDelete={e => {
        const { [e]: property, ...rest } = values.properties;

        setFieldValue("properties", Object.values(rest));
      }}
    ></Form>
  ) : (
    <PropertyForm
      subtitle="Datensatz erstellen"
      handleClose={() => setSelectedProperty(false)}
      values={values.properties[selectedProperty]}
      handleChange={(key, value) =>
        setFieldValue(`properties.${selectedProperty}.${key}`, value)
      }
    />
  );
};

export default connect(
  (state, { id }) => ({ entry: state.entries.items[id] }),
  { update, add }
)(CreateEntry);
