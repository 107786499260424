import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { EuiButtonIcon, EuiPopover, EuiRange } from "@elastic/eui";
import { setViewState, setRadius } from "ducks/ui/entriesMap";
import { useAddQueryNavigation } from "helpers/hooks/url";
import { useHistory } from "react-router-dom";
const Options = () => {
  const mapState = useSelector(state => state.entriesMap);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const addQuery = useAddQueryNavigation();
  const history = useHistory();

  return (
    <div>
      {mapState.picked.id && (
        <>
          <EuiButtonIcon
            onClick={() => history.push(`/entries/${mapState.picked.id}`)}
            iconType="eye"
          />
          <EuiButtonIcon
            onClick={() => addQuery("editEntry", mapState.picked.id)}
            iconType="pencil"
          />
        </>
      )}
      <EuiButtonIcon
        onClick={() =>
          dispatch(
            setViewState({
              ...mapState.viewState,
              longitude: mapState.picked.coordinates[0],
              latitude: mapState.picked.coordinates[1],
              zoom: 15
            })
          )
        }
        iconType="crosshairs"
      />
      <EuiPopover
        button={
          <EuiButtonIcon
            iconType="expand"
            onClick={() => setOpen(!open)}
          ></EuiButtonIcon>
        }
        isOpen={open}
        closePopover={() => setOpen(false)}
      >
        <div style={{ width: "300px" }}>
          <EuiRange
            showInput
            min={0}
            max={1000}
            step={10}
            value={mapState.radius}
            onChange={e => dispatch(setRadius(parseInt(e.target.value, 10)))}
            showLabels
            showValue
            aria-label="An example of EuiRange with showValue prop"
          />
        </div>
      </EuiPopover>
    </div>
  );
};
export default Options;
