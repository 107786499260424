import React from "react";
import Form from "./Form";
import { useFormik } from "formik";
import { create } from "ducks/files";
import { add } from "ducks/toasts";

import { connect } from "react-redux";
import { useRemoveQueryNavigation } from "helpers/hooks/url";

const CreateFile = ({ add, create }) => {
  const onClose = useRemoveQueryNavigation("createFile");

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: "",
      file: ""
    },
    onSubmit: ({ name, file }) => {
      const data = new FormData();
      data.append("file", file[0]);
      data.append("name", name);

      create(data);
      add(
        "Update erfolgreich",
        "Datei wurde erfolgreich erstellt",
        "success",
        "user"
      );
    }
  });

  return (
    <Form
      subtitle="Datei hochladen"
      values={values}
      handleChange={setFieldValue}
      handleSubmit={handleSubmit}
      handleClose={onClose}
    ></Form>
  );
};

export default connect(null, { create, add })(CreateFile);
