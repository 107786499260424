import React from "react";
import Form from "./Form";
import { useFormik } from "formik";
import { update } from "ducks/entries";
import { add } from "ducks/toasts";

import { connect } from "react-redux";
import { useRemoveQueryNavigation } from "helpers/hooks/url";
const EditEntry = ({ add, update, entry }) => {
  const onClose = useRemoveQueryNavigation("editEntry");
  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: entry,

    onSubmit: values => {
      update(values);
      add(
        "Update erfolgreich",
        "Benutzer wurde erfolgreich aktualisiert",
        "success",
        "user"
      );
    }
  });
  return values ? (
    <Form
      subtitle="Eintrag bearbeiten"
      values={values}
      handleChange={setFieldValue}
      handleSubmit={handleSubmit}
      handleClose={onClose}
    ></Form>
  ) : (
    false
  );
};

export default connect(
  (state, { id }) => ({ entry: state.entries.items[id] }),
  { update, add }
)(EditEntry);
