import React, { useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { EuiInMemoryTable, EuiButton } from "@elastic/eui";
import { useAddQueryNavigation } from "helpers/hooks/url";
import { filterdEntries } from "selectors/filter";
import { useHistory } from "react-router-dom";
import Content from "components/Content";

import Page from "components/Page";
import FilterBar from "features/FilterBar";
import { BadgeColumn } from "components/Columns";
import CollectionChanger from "./CollectionChanger";
const EntriesList = () => {
  const entries = useSelector(filterdEntries);
  const distanceRules = useSelector(state => state.distance_rules.items);
  const collections = useSelector(state => state.collections.items);
  const [selection, setSelection] = useState();
  const addQuery = useAddQueryNavigation();
  const history = useHistory();
  const tableData = useMemo(() => {
    return entries.map(e => {
      return {
        id: e.id,
        kollisionen: e.collisions.total,
        eintrag: e.entryFormat.join(", "),
        kollektion: e.collection_ids.map(e => collections[e].name)
      };
    });
  }, [entries, collections]);

  const kollektionOptions = useMemo(() => {
    return tableData
      .map(e => e.kollektion)
      .flat()
      .filter((e, i, a) => a.indexOf(e) === i)
      .map(e => ({ name: e, value: e }));
  }, [tableData]);

  const actions = [
    {
      name: "Löschen",
      description: "Diesen Eintrag löschen",
      icon: "trash",
      color: "danger",
      type: "icon",
      onClick: e => {
        addQuery("deleteEntry", e.id);
      }
    },
    {
      name: "Edit",
      isPrimary: true,
      description: "Diesen Eintrag bearbeiten",
      icon: "pencil",
      type: "icon",

      onClick: e => {
        addQuery("editEntry", e.id);
      }
    }
  ];

  const columns = [
    {
      name: "Eintrag",
      field: "eintrag",
      sortable: true,
      dataType: "string"
    },
    {
      render: e => <BadgeColumn entries={e} truncate={20} />,
      name: "Kollektion",
      field: "kollektion",
      sortable: true,
      dataType: "string"
    },
    {
      name: "Kollisionen",
      field: "kollisionen",
      sortable: true,
      dataType: "number"
    },
    {
      name: "Aktionen",
      actions,
      width: "100px"
    }
  ];
  const search = {
    box: {
      incremental: true,
      schema: true
    },
    executeQueryOptions: ["eintrag", "kollektion"],
    filters: [
      {
        type: "field_value_toggle",
        field: "kollisionen",
        name: "Mit Kollisionen",
        value: 0,
        operator: "gt"
      },
      {
        type: "field_value_selection",
        field: "kollektion",
        name: "Kollektionen",
        multiSelect: "or",
        options: kollektionOptions
      }
    ]
  };
  return (
    <Page
      title="Einträge"
      sidebar={<FilterBar></FilterBar>}
      actions={<NewEntryButton />}
    >
      <Content
        title="Übersicht"
        actions={<CollectionChanger entries={tableData} />}
      >
        <EuiInMemoryTable
          rowProps={row => ({
            onClick: function(e) {
              if (e.nativeEvent.target.nodeName !== "BUTTON") {
                history.push(`/entries/${row.id}`);
              }
            }
          })}
          hasActions
          items={tableData}
          columns={columns}
          tableLayout="auto"
          pagination={true}
          search={search}
          sorting={{ sort: { field: "eintrag", direction: "asc" } }}
        />
      </Content>
    </Page>
  );
};

const NewEntryButton = () => {
  const history = useHistory();

  return (
    <EuiButton
      fill
      iconType="plusInCircle"
      onClick={() => history.push("/entries/new")}
    >
      Eintrag hinzufügen
    </EuiButton>
  );
};

export default EntriesList;
