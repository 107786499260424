import React from "react";
import Form from "components/Forms/Property";
import { useFormik } from "formik";
import { createWithEntry } from "ducks/properties";
import { add } from "ducks/toasts";

import { connect } from "react-redux";
import { useRemoveQueryNavigation } from "helpers/hooks/url";
import { useLocation } from "react-router-dom";
const CreateProperty = ({ add, createWithEntry, entry_id }) => {
  const onClose = useRemoveQueryNavigation("createProperty");
  const location = useLocation();
  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: location.state ?? {
      data: {},
      entry_id
    },
    onSubmit: values => {
      createWithEntry(values);
      add(
        "Update erfolgreich",
        "Benutzer wurde erfolgreich erstellt",
        "success",
        "user"
      );
    }
  });

  return (
    <Form
      subtitle="Datensatz erstellen"
      values={values}
      handleChange={setFieldValue}
      handleSubmit={handleSubmit}
      handleClose={onClose}
    ></Form>
  );
};

export default connect(null, { createWithEntry, add })(CreateProperty);
