import React from "react";
import Form from "./Form";
import { useFormik } from "formik";
import { update } from "ducks/files";
import { add } from "ducks/toasts";

import { connect } from "react-redux";
import { useRemoveQueryNavigation } from "helpers/hooks/url";

const EditFile = ({ file, add, update }) => {
  const onClose = useRemoveQueryNavigation("editFile");
  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: file,
    enableReinitialize: true,
    onSubmit: ({ name, file, id }) => {
      const data = new FormData();
      if (file) {
        data.append("file", file[0]);
      }
      data.append("name", name);
      data.append("id", id);
      update(data);
      add(
        "Update erfolgreich",
        "Datei wurde erfolgreich erstellt",
        "success",
        "user"
      );
    }
  });

  return values === undefined ? null : (
    <Form
      subtitle="Datei bearbeiten"
      values={values}
      handleChange={setFieldValue}
      handleSubmit={handleSubmit}
      handleClose={onClose}
    ></Form>
  );
};
const mapState = (state, { id }) => ({ file: state.files.items[id] });
export default connect(mapState, { update, add })(EditFile);
