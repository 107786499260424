import React, { useState, useEffect } from "react";
import { EuiSuggest } from "@elastic/eui";
import useGeocode from "helpers/hooks/useGeocode";
import { EuiButtonEmpty } from "@elastic/eui";
const GeoCodeField = ({ onChange, handleClick }) => {
  const [results, setQuery] = useGeocode();
  const [value, setValue] = useState("");
  const [selection, setSelection] = useState(false);
  useEffect(() => {
    if (value.length > 1) {
      setQuery(value);
    }
  }, [value]);
  return (
    <EuiSuggest
      append={
        <EuiButtonEmpty onClick={() => handleClick(selection)}>
          +
        </EuiButtonEmpty>
      }
      value={value}
      onInputChange={e => setValue(e.value)}
      onItemClick={e => {
        setValue(e.label);
        setSelection(e.data);
        onChange(e);
      }}
      suggestions={results.map(e => ({
        type: { iconType: "globe", color: "tint3" },
        label: `${e.Straße} ${e.Hausnummer}, ${e.Postleitzahl} ${e.Ort}`,
        data: e
      }))}
    ></EuiSuggest>
  );
};

export default GeoCodeField;
